export const generateCanonicalUrl = (urlString: string): string => {
  const allowedParams = new Set(['page', 'tipo']);
  const canonicalUrl = new URL(urlString);

  for (const [key] of canonicalUrl.searchParams) {
    if (!allowedParams.has(key)) {
      canonicalUrl.searchParams.delete(key);
    }
  }

  canonicalUrl.protocol = 'https:';
  return canonicalUrl.toString();
};
